import React, { useContext, useState } from 'react';

/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";

import MsgBox from '../chatBox/MsgBox';
import { ChatBoxContext } from './chatboxStore';

const ChatBox = () => {
  const { chatVisible, setChatVisible } = useContext(ChatBoxContext);
  const onClick = () => setChatVisible(!chatVisible);
  return (
    <div className={`chatbox ${chatVisible ? 'active' : ''}`}>
      <div className="chatbox-close" onClick={() => onClick()}></div>
      <div className="custom-tab-1">
        {/* <ul className="nav nav-tabs">
          {dataToggle.map((data, i) => (
            <li className="nav-item" key={i}>
              <Link
                className={`nav-link ${
                  toggleTab === data.name.toLocaleLowerCase() ? 'active' : ''
                }`}
                to="#"
                data-toggle="tab"
                href={data.href}
                onClick={() => settoggleTab(data.name.toLocaleLowerCase())}
              >
                {data.name}
              </Link>
            </li>
          ))}
        </ul> */}
        <div className="tab-content">
          <MsgBox
            avatar1={'avatar1'}
            avatar2={'avatar2'}
            openMsg={true}
            //PerfectScrollbar={PerfectScrollbar}
            offMsg={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
