import useUser from '../../hooks/useUser';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { setUserDetails } from '../../reducers/auth';
import ChatBoxProvider from './chatboxStore';

import Nav from './nav';

function Layout() {
  const auth = useSelector((state) => state.auth);
  const { keepParentLayout } = useSelector((state) => state.title);
  const isSessionExpired = auth.isSessionExpired;
  const sideMenu = useSelector((state) => state.auth.isSideMenuVisible);
  const { userData } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      dispatch(setUserDetails(userData));
    }
  }, [userData, auth?.isLoggedIn, dispatch]);

  const isLoading = false;
  return (
    // <ChatBoxProvider>
    //   <div className="h-full overflow-hidden flex flex-row pr-6 gap-4" id="container">
    //     <Sidebar />
    //     <div className="flex flex-col gap-6 flex-1 max-w-full px-2 overflow-x-hidden  scroll-content overflow-y-scroll py-6">
    //       <Header headerText={''} isLoading={isLoading} />
    //       {isSessionExpired ? (
    //         <SessionExpiredPopup />
    //       ) : (
    //         <div
    //           className={classNames('flex-1  z-10 h-fit', {
    //             ' ring-greyscale-900 dark:ring-greyscale-200 ring-1 p-6 rounded-xl  backdrop-blur-lg':
    //               keepParentLayout,
    //           })}
    //         >
    //           <Outlet />
    //         </div>
    //       )}
    //     </div>
    //     <ChatBox />
    //   </div>
    // </ChatBoxProvider>
    <ChatBoxProvider>
      <div id="main-wrapper" className={`show ${sideMenu ? 'menu-toggle' : ''}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </ChatBoxProvider>
  );
}

export default Layout;
