import useTitle from '../../../hooks/useTitle';
import { useQuery } from '@tanstack/react-query';
import { Input, TextArea, Tooltip } from '../../../components/ui-components';
import TableCustom from '../../../components/ui-components/table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ChatBoxContext } from '../../../components/layout/chatboxStore';
import { capitalizeFirstLetter } from 'src/utils/common-utils';
import { CreateAgentContext } from '.';
import {
  deleteIntent,
  getAgentDetail,
  getDatasourceList,
  getIntentList,
  useCreateAgent,
} from './service';
import ConfirmPublishAgent from 'src/components/modals/agents/publish-agent';
import CreateSkeleton from '../../../components/ui-components/skeleton/datasource-create';
import DropdownBootstrap from 'src/components/ui-components/dropdown-Input';
import { Card, Table, Button } from 'react-bootstrap';

const CreateAgent = () => {
  useTitle('Create Agent');
  const {
    agentId,
    handleAgentId,
    handleSubmit,
    errors,
    control,
    setValue,
    trigger,
    getValues,
  } = useContext(CreateAgentContext);
  const {
    createAgentMutation,
    data: agentResponse,
    isLoading: createAgentLoading,
  } = useCreateAgent();
  const { setChatVisible } = useContext(ChatBoxContext);

  const [params, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const [publishAgent, setPublishAgent] = useState(false);
  const [agentDetailLoading, setAgentDetailLoading] = useState(false);
  const handleCreateIntent = () => {
    navigate(`/agents/intent/create/${id}`);
  };
  const { data: datasourceList } = useQuery({
    queryKey: ['datasource', 'list'],
    enabled: true,
    queryFn: () => getDatasourceList(),
    retry: false,
  });

  const {
    data: intentList,
    refetch: refetchIntent,
    isLoading: intentLoading,
  } = useQuery({
    queryKey: ['intent', 'list'],
    enabled: true,
    queryFn: () => getIntentList(id || agentId),
    retry: false,
  });

  const onSubmit = (values) => {
    const body = values;
    body.agentId = agentId;
    body.data_source = getValues().data_source[0]?.id;
    createAgentMutation(body);
  };
  const handleDeleteIntent = async (id) => {
    const response = await deleteIntent(id);
    if (response?.status) {
      toast.success(response?.message);
    } else {
      toast.error(response?.message);
    }
    refetchIntent();
  };
  const editIntent = (id) => {
    navigate(`/agents/intent/update/${id}`);
  };
  const setAgentDetail = async (id) => {
    const response = await getAgentDetail(id);
    setValue('name', response?.name);
    trigger('name');
    setValue('description', response?.description);
    trigger('description');
    setValue('prompt', response?.prompt);
    trigger('prompt');
    const dataSource = {
      value: response?.data_source.id,
      label: response?.data_source.title,
    };
    setValue('data_source', response?.data_source ? dataSource : {});
    trigger('data_source');
    setAgentDetailLoading(false);
  };
  useEffect(() => {
    if (id) {
      setAgentDetail(id);
      handleAgentId(id);
      setAgentDetailLoading(true);
    }
  }, [id]);
  useEffect(() => {
    if (agentResponse) {
      handleAgentId(agentResponse?.id);
      getIntentList(agentResponse?.id);
    }
  }, [agentResponse]);
  const handlePublishAgent = () => {
    setPublishAgent(!publishAgent);
  };
  const memorizedColumns = useMemo(
    () => [
      {
        accessorFn: (row) => capitalizeFirstLetter(row?.name),
        id: 'title',
        size: 260,
        enableSorting: true,
        meta: { tooltip: true },
        header: () => <span>Title</span>,
        cell: (info) => {
          return (
            <Link onClick={() => editIntent(info.row.original.id)}>
              <span className="text-ellipsis max-w-[50%] hover:underline text-primary-600 dark:text-primary-300">
                {info.row.original.title}
              </span>
            </Link>
          );
        },
      },
      {
        accessorFn: (row) => capitalizeFirstLetter(row?.name),
        id: 'date',
        size: 260,
        enableSorting: true,
        meta: { tooltip: true },
        header: () => <span>Created Date</span>,
        cell: (info) => {
          return (
            <span className="text-ellipsis max-w-[50%] text-primary-600 dark:text-primary-300">
              {new Date(info.row.original.created_at).toDateString()}
            </span>
          );
        },
      },
      {
        accessorFn: () => 'Actions',
        id: 'bots',
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <div className="d-flex">
              <Tooltip tooltip="Edit">
                <button
                  className="btn btn-primary shadow btn-xs sharp me-1"
                  onClick={() => editIntent(row?.original.id)}
                >
                  <i className="fas fa-pencil-alt"></i>
                </button>
              </Tooltip>
              <Tooltip tooltip="Delete">
                <button
                  className="btn btn-danger shadow btn-xs sharp"
                  onClick={() => handleDeleteIntent(row?.original.id)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </Tooltip>
            </div>
          );
        },
        header: () => <div className="text-right">Actions</div>,
        footer: (props) => props.column.id,
        minSize: 50,
      },
    ],
    [],
  );

  return (
    <>
      {agentDetailLoading ? (
        <CreateSkeleton />
      ) : (
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div className="w-100 d-flex flex-row justify-content-end">
                <button
                  onClick={() => setChatVisible(true)}
                  className="btn btn-primary me-2"
                  disabled={!agentId}
                >
                  <span className="btn-icon-right me-2">
                    <i className="fa fa-play" aria-hidden="true"></i>
                  </span>
                  Test
                </button>
                <button
                  onClick={handlePublishAgent}
                  className="btn btn-primary"
                  disabled={!agentId}
                >
                  <span className="btn-icon-right me-2">
                    <i className="fa fa-upload" aria-hidden="true"></i>
                  </span>
                  Publish
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="form-valide">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="form-group mb-3 row">
                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            validate: {
                              require: (value) => {
                                if (!value?.trim()) return 'Required';
                              },
                            },
                            required: true,
                          }}
                          render={({ field: { name, onChange, value } }) => (
                            <Input
                              name={name}
                              label="Title"
                              required
                              placeholder="Title"
                              onChange={onChange}
                              error={errors && !value?.trim() ? errors.name : undefined}
                              value={value ? value : ''}
                            />
                          )}
                        />
                      </div>
                      <div className="form-group mb-3 row">
                        <Controller
                          name="description"
                          control={control}
                          rules={{
                            required: true,
                            validate: {
                              require: (value) => {
                                if (!value?.trim()) return 'Required';
                              },
                            },
                          }}
                          render={({ field: { name, onChange, value } }) => (
                            <TextArea
                              name={name}
                              label="Description"
                              placeholder="Description"
                              rows={10}
                              value={value ? value : ''}
                              onChange={onChange}
                              required
                              error={
                                errors && !value?.trim() ? errors.description : undefined
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group mb-3 row">
                        <Controller
                          name="data_source"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <DropdownBootstrap
                                onChange={onChange}
                                value={value}
                                items={datasourceList}
                                label="Data Source"
                                error={!!errors?.data_source && !value}
                                required
                              />
                            );
                          }}
                        />
                      </div>
                      <div className="form-group mb-3 row">
                        <Controller
                          name="prompt"
                          control={control}
                          rules={{
                            required: true,
                            validate: {
                              require: (value) => {
                                if (!value?.trim()) return 'Required';
                              },
                            },
                          }}
                          render={({ field: { name, onChange, value } }) => (
                            <TextArea
                              name={name}
                              label="Prompt"
                              placeholder="Write your prompt here..."
                              rows={10}
                              value={value ? value : ''}
                              onChange={onChange}
                              required
                              error={
                                errors && !value?.trim() ? errors.description : undefined
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="form-group mb-3 row">
                        <div className="col-lg-8 d-flex justify-content-end  w-100">
                          <button
                            onClick={() => navigate(-1)}
                            type="button"
                            className="btn btn-outline-primary me-4"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            className="btn btn-primary"
                            disabled={createAgentLoading}
                          >
                            {agentId ? 'Update' : 'Save'}
                            <span className="btn-icon-right ms-2">
                              <i className="fa fa-check"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Card>
                <Card.Header className="d-flex justify-content-between  ps-0 pe-0">
                  <Card.Title>INTENTS</Card.Title>
                  <Button
                    className="me-2"
                    variant="primary"
                    size="sm"
                    disabled={agentId == ''}
                    onClick={handleCreateIntent}
                  >
                    <span className="btn-icon-right me-2">
                      <i className="fa fa-plus" />
                    </span>
                    Create New Intent
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <TableCustom
                      columns={memorizedColumns}
                      data={agentId ? (intentList !== undefined ? intentList : []) : []}
                      page={10}
                      total={0}
                      isLoading={intentLoading}
                      onChangePage={() => {
                        // params.set('page', page.toString());
                        // setSearchParams(params);
                      }}
                      onChangeSort={(value) => {
                        const { id } = value()[0];
                        params.set(
                          'direction',
                          params.get('direction') === 'asc' ? 'desc' : 'asc',
                        );
                        params.set('sort', id);
                        params.set('page', '1');
                        setSearchParams(params);
                      }}
                      sort={[
                        {
                          id: params.get('sort') || '',
                          desc: params.get('direction') === 'desc' ? true : false,
                        },
                      ]}
                      emptyMessage="Intents"
                    />
                  </Table>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      )}
      {publishAgent && (
        <ConfirmPublishAgent
          show={publishAgent}
          onClose={setPublishAgent}
          publishAgent={handlePublishAgent}
          agentId={agentId}
        />
      )}
    </>
  );
};

export default CreateAgent;
