import React from 'react';
import Skelton from '../index';

const CreateSkeleton = () => {
  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="d-flex flex-row gap-2">
        <Skelton className="h-12 w-100 rounded" />
        <Skelton className="h-12 w-20 rounded" />
      </div>

      <div className="d-flex justify-content-between w-100 items-end mt-6">
        <div
          style={{ gap: '0.5rem' }}
          className="w-100 d-flex justify-between  align-items-center"
        >
          <div className="w-1/2">
            <Skelton className="h-8 w-100 rounded mb-4" />
            <Skelton className="h-8 w-100 rounded" />
          </div>
          <div className="w-1/2">
            <Skelton className="h-8 w-100 rounded mb-4" />
            <Skelton className="h-8 w-100 rounded" />
          </div>
          <div className="pt-7">
            <Skelton className="h-10 w-24 rounded" />
          </div>
        </div>
      </div>

      <div className="d-flex gap-6 my-7 align-items-center justify-content-center">
        <Skelton className="h-10 w-36 rounded-md" />
        <Skelton className="h-10 w-36 rounded-md" />
        <Skelton className="h-10 w-36 rounded-md" />
      </div>

      {/* Placeholder for content */}
      <Skelton className="h-64 w-100 rounded" />

      {/* Bottom Action Buttons */}
      <div className="d-flex gap-2 align-items-center justify-end w-100 fixed bottom-4 right-4">
        <Skelton className="h-10 w-24 rounded" />
        <Skelton className="h-10 w-24 rounded" />
      </div>
    </div>
  );
};

export default CreateSkeleton;
