import classNames from 'classnames';

export default function Skelton({ type, width = '', height = 3, className }) {
  const singleLine = (lineWidth = width, lineHeigh = height, SingleLineClass = '') => (
    <div
      style={{ background: 'red' }}
      className={classNames('animate-pulse bg-gray-200  rounded-md', {
        [`w-${lineWidth}`]: lineWidth,
        [`h-${lineHeigh}`]: lineHeigh,
        'w-100': !lineWidth,
        [`${SingleLineClass}`]: SingleLineClass,
      })}
    />
  );
  switch (type) {
    case 'integration-card':
      return (
        <div
          style={{ background: 'red' }}
          className="bg-grey  p-6 rounded-md space-y-2 w-100"
        >
          <div className="d-flex w-100 mb-4 justify-content-between">
            {singleLine('14', '14', '!rounded-xl')}
            {singleLine('', Number(height) * 4, '!w-32')}
          </div>
          {singleLine('28', '4')}
          {singleLine('44', height, '!mb-2')}
        </div>
      );
    case 'single':
    default:
      return singleLine(width, height, className);
  }
}
