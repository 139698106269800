import React from 'react';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from './Logout';
/// Image
import profile from '../../../images/profile/17.jpg';
import { Dropdown } from 'react-bootstrap';
import { useLogout } from '../../../services/user.service';
import { useQueryClient } from '@tanstack/react-query';
import { logout } from '../../../reducers/auth';
const Header = () => {
  const userDetails = useSelector((state) => state.auth?.userDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { title, subTitle, enableReturn } = useSelector((state) => state.title);
  const { LogoutMutation, isSuccess, isLoading: isLoggingOutLoad } = useLogout();

  useEffect(() => {
    if (isSuccess) {
      queryClient.clear();
      dispatch(logout({ payload: null }));
      navigate('/');
    }
  }, [dispatch, isSuccess, navigate, queryClient]);

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="dashboard_bar" style={{ textTransform: 'capitalize' }}>
                {title}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <Dropdown as="li" className={`nav-item header-profile `}>
                <Dropdown.Toggle className="nav-link i-false" as="a">
                  <img
                    src={userDetails?.image ? userDetails.image : profile}
                    alt={userDetails?.name}
                    width="20"
                  />
                  <div className="header-info">
                    <span className="text-black">{userDetails?.name}</span>
                    <p className="fs-12 mb-0">Super Admin</p>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`dropdown-menu-right`}>
                  <Link to="/profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span className="ms-2">Profile </span>
                  </Link>
                  {/* <div
                    // to="/email-inbox"
                    className="dropdown-item ai-icon"
                  >
                    <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <span className="ms-2">Inbox </span>
                  </div> */}
                  <LogoutPage logout={LogoutMutation} />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
