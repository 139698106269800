import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useChatMutation } from './chat.service';
import { ChatBoxContext } from '../layout/chatboxStore';
import { getAgentDetail } from '../../pages/agents/create/service';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock-icon.svg';
import { Icons } from '../../assets';
import './loder.css';
import Input from '../ui-components/input';
import { Button } from 'react-bootstrap';
const MsgBox = ({ avatar1, avatar2, openMsg }) => {
  const { chatVisible, setChatVisible } = useContext(ChatBoxContext);
  const [conversationId, setConversationId] = useState('');
  const [intentId, setIntentId] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [agentDetails, setagentDetails] = useState({ name: '' });
  const [refresh, setRefresh] = useState(false);
  const [chat, setChat] = useState([]);
  const [formData, setFormData] = useState({});
  const inputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const params = useParams();
  const agentId = params.id;

  const { ChatMutation, data, isLoading } = useChatMutation();
  const clearConversation = () => {
    setRefresh(true);
    setChat([]);
    setFormData({});
    setIntentId('');
    setSuggestions([]);
    setConversationId('');
    setTimeout(() => {
      setRefresh(false);
    }, 500);
  };
  async function fetchAgentDetails() {
    if (agentId) {
      const response = await getAgentDetail(agentId);
      setagentDetails(response);
    }
  }
  useEffect(() => {
    // const conId = localStorage.getItem('conversationId');
    if (chatVisible && agentId && !conversationId) {
      ChatMutation({
        agent_id: agentId,
        message: '',
        message_type: 'text',
        conversation_id: '',
        intent_id: '',
      });
    }
    if (agentDetails.name == '') {
      fetchAgentDetails();
    }
  }, [ChatMutation, agentDetails.name, agentId, chatVisible, conversationId]);
  const handleSend = () => {
    if (inputRef.current?.value != '') {
      const body = {
        msg: inputRef.current?.value || '',
        user: 'user',
      };
      setChat([...chat, body]);

      ChatMutation({
        agent_id: agentId,
        message: inputRef.current?.value,
        message_type: 'text',
        conversation_id: conversationId,
        intent_id: intentId,
      });
      inputRef.current.value = '';
      setSuggestions([]);
    }
  };
  useEffect(() => {
    if (data) {
      const tempChat = [...chat];
      tempChat.push({ user: 'agent', msg: data?.response });
      setSuggestions(data?.suggestions);
      setFormData(data?.form);
      setChat(tempChat);
      if (!conversationId) {
        setConversationId(data?.conversation_id);
      }
      setIntentId(data?.intent_id);
    }
  }, [data]);
  useEffect(() => {
    chatContainerRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
  }, [chat]);
  const optionSelected = (val) => {
    inputRef.current.value = val;
    handleSend();
    setSuggestions([]);
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    let message = `<form style="diplay:flex;flex-direction:column;text-align:left">`;
    formData?.data.forEach((items) => {
      items['value'] = Object.fromEntries(form.entries())[items.label];
    });

    formData?.data.forEach((data) => {
      if (data.type == 'string') {
        message += `<label  htmlFor=${data.label}>
                ${data.label}
              </label>
              <input
              readonly
              style="display:flex;flex-grow: 1;margin: 5px 0px 8px 0px; height:2rem;padding:10px; background-color:transparent; border: 1px solid black; border-radius: 10px; "
              value="${data.value}"
              type='text'
              />
              `;
      } else if (data.type == 'boolean') {
        message += `<label  htmlFor=${data.label}>
                ${data.label}
              </label>
              <input
              disabled="true"
              style="display:flex;flex-grow: 1;margin: 5px 0px 8px 0px; height:2rem;padding:10px; background-color:transparent; border: 1px solid black; border-radius: 10px; "
              checked="${data.value == 'on' ? true : false}"
              type='checkbox'
              />
              `;
      } else if (data.type == 'integer') {
        message += `<label  htmlFor=${data.label}>
                ${data.label}
              </label>
              <input
              readonly
              style="display:flex;flex-grow: 1;margin: 5px 0px 8px 0px; height:2rem;padding:10px; background-color:transparent; border: 1px solid black; border-radius: 10px; "
              value="${data.value}"
              type='number'
              />
              `;
      } else if (data.type == 'date') {
        message += `<label  htmlFor=${data.label}>
                ${data.label}
              </label>
              <input
              readonly
              style="display:flex;flex-grow: 1;margin: 5px 0px 8px 0px; height:2rem;padding:10px; background-color:transparent; border: 1px solid black; border-radius: 10px; "
              value="${data.value}"
              type='date'
              />
              `;
      } else if (data.type == 'time') {
        message += `<label  htmlFor=${data.label}>
                ${data.label}
              </label>
              <input
              readonly
              style="display:flex;flex-grow: 1;margin: 5px 0px 8px 0px; height:2rem;padding:10px; background-color:transparent; border: 1px solid black; border-radius: 10px; "
              value="${data.value}"
              type='time'
              />
              `;
      }
    });
    message += '</form>';

    const body = {
      msg: message,
      user: 'user',
    };
    setChat([...chat, body]);

    ChatMutation({
      agent_id: agentId,
      message,
      message_type: 'text',
      conversation_id: conversationId,
      intent_id: intentId,
      form: formData,
    });
    setFormData({});
  };
  return (
    <div className={`card chat dz-chat-history-box ${openMsg ? '' : 'd-none'}`}>
      <div className="card-header chat-list-header text-center">
        <div>
          <span style={{ gap: '4px' }} className="d-flex flex-row">
            <h6 className="mb-1">{agentDetails?.name}</h6>
            <p className="mb-0 text-success">Online</p>
          </span>
          <span style={{ gap: '4px' }} className="d-flex flex-row">
            <ClockIcon />
            <p className="fa-regular">Usual reply time: 30 to 60 Seconds</p>
          </span>
        </div>
        <button onClick={clearConversation} className="btn shadow btn-xs">
          {!refresh ? (
            <Icons.Reload />
          ) : (
            <span role="status" className="spinner-border spinner-border-sm" />
          )}
        </button>
      </div>
      <div
        ref={chatContainerRef}
        className={`card-body msg_card_body dz-scroll ${openMsg ? '' : ''} `}
        id="DZ_W_Contacts_Body3"
      >
        {chat.map((item) => {
          return item.user === 'agent' ? (
            <div key={item.msg[1]} className="d-flex justify-content-start mb-4">
              <div className="img_cont_msg">
                <img src={avatar1} className="rounded-circle user_img_msg" alt="" />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: item.msg }}
                className="msg_cotainer"
              >
                {/* <span className="msg_time">8:40 AM, Today</span> */}
              </div>
            </div>
          ) : (
            <div key={item.msg[2]} className="d-flex justify-content-end mb-4">
              <div
                dangerouslySetInnerHTML={{ __html: item.msg }}
                className="msg_cotainer_send"
              >
                {/* <span className="msg_time_send">8:55 AM, Today</span> */}
              </div>
              <div className="img_cont_msg">
                <img src={avatar2} className="rounded-circle user_img_msg" alt="" />
              </div>
            </div>
          );
        })}
        {suggestions.length !== 0 && (
          <div className="d-flex justify-content-start mb-4">
            {suggestions.map((item) => {
              return (
                <button
                  className={''}
                  key={item.title}
                  onClick={() => optionSelected(item.title)}
                >
                  <div>{item.text}</div>
                </button>
              );
            })}
          </div>
        )}
        {Object.keys(formData).length !== 0 && (
          <div className="d-flex justify-content-end mb-4">
            <form
              onSubmit={handleSubmitForm}
              className="msg_cotainer_send"
              style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}
            >
              {formData?.data.map((data) => {
                if (data.type == 'string') {
                  return (
                    <>
                      <label className="block mt-2 mb-1" htmlFor={data.label}>
                        {data.label}
                      </label>
                      <Input key={data.id} type={'text'} name={data.label} />
                    </>
                  );
                } else if (data.type == 'boolean') {
                  return (
                    <div
                      key={data.id}
                      style={{ alignItems: 'flex-start', paddingLeft: '2px' }}
                      className="d-flex flex-column w-100 h-fit "
                    >
                      <label htmlFor={data.label}>{data.label}</label>
                      <input
                        type={'checkbox'}
                        name={data.label}
                        className="h-8 bg-white"
                      />
                    </div>
                  );
                } else if (data.type == 'date') {
                  return (
                    <>
                      <label className="block mt-2 mb-1" htmlFor={data.label}>
                        {data.label}
                      </label>
                      <Input
                        key={data.id}
                        type={'date'}
                        name={data.label}
                        className="h-8 !text-white"
                        containerClassName="ring-white"
                      />
                    </>
                  );
                } else if (data.type == 'time') {
                  return (
                    <>
                      <label className="block mt-2 mb-1" htmlFor={data.label}>
                        {data.label}
                      </label>
                      <Input
                        key={data.id}
                        type={'time'}
                        name={data.label}
                        className="h-8 !text-white"
                        containerClassName="ring-white"
                      />
                    </>
                  );
                } else if (data.type == 'integer') {
                  return (
                    <>
                      <label className="block mt-2 mb-1" htmlFor={data.label}>
                        {data.label}
                      </label>
                      <Input
                        key={data.id}
                        type={'number'}
                        min={0}
                        max={40}
                        name={data.label}
                        className="h-8 !text-white"
                        containerClassName="ring-white"
                      />
                    </>
                  );
                }
              })}
              <Button type="submit" className="mt-4  btn-sm">
                Submit
              </Button>
            </form>
          </div>
        )}
        {isLoading && (
          <div className="d-flex justify-content-start mb-4" key={'#id'}>
            <div className="message-loader-2">
              <div className="loader-bar-2"></div>
              <div className="loader-bar-2"></div>
              <div className="loader-bar-2"></div>
            </div>
          </div>
        )}
      </div>
      <div className="card-footer type_msg">
        <div className="input-group">
          <textarea
            ref={inputRef}
            placeholder="Enter message ..."
            className="form-control"
            style={{ resize: 'none', border: '2px' }}
            rows={2}
            onKeyDown={(e) => {
              if (!isLoading && e.key === 'Enter') {
                e.preventDefault();
                handleSend();
              }
            }}
          />
          <div className="input-group-append">
            <button onClick={handleSend} type="button" className="btn btn-primary">
              <i className="fa fa-location-arrow"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MsgBox;
