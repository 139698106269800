import { Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import NotFoundPage from 'src/pages/404';

import AuthLayout from '../components/auth/layout';
import Layout from '../components/layout';
import {
  AgentCreate,
  Actions,
  ActionsCreate,
  AgentListing,
  AuthRedirect,
  ChangePassword,
  Conversation,
  ConversationDetails,
  CreateEntity,
  Datasource,
  DataSourceList,
  EntityListing,
  ForgotPassword,
  Login,
  NoAccessPage,
  Profile,
  ResetPassword,
  Signup,
  WebScrapingResultList,
  CreateIntent,
} from '../pages';

const withFallback = (Component: any) => {
  return (
    <Suspense fallback={<></>}>
      <Component />
    </Suspense>
  );
};

const ErrorHandle = () => {
  useEffect(() => {
    window.location.reload();
  }, []);
  return <></>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" ErrorBoundary={() => <ErrorHandle />} element={<AuthRedirect />}>
      <Route path="/" element={<AuthLayout />}>
        <Route path="login" element={withFallback(Login)} />
        <Route path="forgot-password" element={withFallback(ForgotPassword)} />
        <Route path="reset-password/:token" element={withFallback(ResetPassword)} />
        <Route path="signup" element={withFallback(Signup)} />
      </Route>
      <Route path="/" element={<Layout />}>
        <Route path="agents/list" element={withFallback(AgentListing)} />
        <Route path="agents/create" element={withFallback(AgentCreate)} />
        <Route path="agents/update/:id" element={withFallback(AgentCreate)} />
        <Route
          path="agents/intent/create/:agentId"
          element={withFallback(CreateIntent)}
        />
        <Route
          path="/agents/intent/update/:intentId"
          element={withFallback(CreateIntent)}
        />
        <Route path="entities" element={withFallback(EntityListing)} />
        <Route path="entities/create" element={withFallback(CreateEntity)} />
        <Route path="entities/update/:id" element={withFallback(CreateEntity)} />
        <Route path="profile" element={withFallback(Profile)} />
        <Route path="change-password" element={withFallback(ChangePassword)} />
        <Route path="datasource" element={withFallback(DataSourceList)} />
        <Route path="datasource/create" element={withFallback(Datasource)} />
        <Route
          path="datasource/details/:id/datasource/url/:urlid"
          element={withFallback(WebScrapingResultList)}
        />
        <Route
          path="datasource/details/:dataSourceId"
          element={withFallback(Datasource)}
        />
        <Route path="actions" element={withFallback(Actions)} />
        <Route path="actions/create" element={withFallback(ActionsCreate)} />
        <Route path="actions/details/:id" element={withFallback(ActionsCreate)} />
        <Route path="conversation" element={withFallback(Conversation)} />
        <Route path="conversation/:id" element={withFallback(ConversationDetails)} />
      </Route>
      <Route path="/no-access" element={withFallback(NoAccessPage)} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>,
  ),
);

function AppRoutes() {
  return (
    <Suspense fallback={<p> Loading...</p>}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default AppRoutes;
