import { Icons } from 'src/assets';
import { useMouse } from '@uidotdev/usehooks';
import { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

const InfoButton = ({ className, info, ...props }) => {
  const tooltipeRef = useRef(null);
  const [mouse] = useMouse();
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
    hover: false,
  });
  const getMousePosition = () => {
    const x = mouse.x;
    const y = mouse.y;
    setMousePosition({ x, y, hover: true });
  };
  const setdefaultMousePosition = () => {
    setMousePosition({ x: 0, y: 0, hover: false });
  };
  // Calculate a suitable width for the tooltip based on content
  const calculateTooltipWidth = useCallback(() => {
    // Adjust the '1.2' multiplier to control the minimum width
    if (typeof info === 'string') {
      const minWidth = Math.min(info.toString().length * 0.2, 20) + 'vw';
      return {
        maxWidth: '20vw',
        minWidth,
      };
    }
  }, [info]);

  const adjustTooltipPosition = () => {
    const tooltipWidth = tooltipeRef?.current?.clientWidth || 0;
    const tooltipHeight = tooltipeRef?.current?.clientHeight || 0;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let adjustedX = mousePosition.x + 5;
    let adjustedY = mousePosition.y + 13;

    if (adjustedX + tooltipWidth > windowWidth) {
      adjustedX = adjustedX - tooltipWidth - 10; // 10px padding from the right edge
    }

    if (adjustedY < 0) {
      adjustedY = mousePosition.y + 20;
    }

    if (adjustedY + tooltipHeight > windowHeight) {
      adjustedY = windowHeight - tooltipHeight - 10;
    }

    return { x: adjustedX, y: adjustedY };
  };

  const adjustedPosition = adjustTooltipPosition();

  return (
    <div
      className={classNames(
        'relative inline-flex justify-center items-center normal-case',
        className,
      )}
      {...props}
    >
      <Icons.InfoOutline
        className="fill-greyscale-200 dark:fill-greyscale-500 w-4 h-auto"
        onMouseEnter={getMousePosition}
        onMouseLeave={setdefaultMousePosition}
      />
      {createPortal(
        <div
          ref={tooltipeRef}
          className={classNames(
            'h-fit absolute top-8 left-0 hidden opacity-0 transition-opacity w-fit z-50',
            {
              'opacity-100 block': mousePosition.hover,
            },
          )}
          style={{
            left: `${adjustedPosition.x}px`,
            top: `${adjustedPosition.y}px`,
          }}
        >
          <div
            role="tooltip"
            className="z-10 px-3 py-2 text-xs leading-5 bg-white ring-greyscale-400 font-medium text-greyscale-400 dark:text-greyscale-300 rounded-lg h-full dark:bg-dark-light dark:ring-greyscale-300 ring-1 shadow-xl text-wrap"
            style={calculateTooltipWidth()} // Apply calculated styles
          >
            {info}
          </div>
        </div>,
        document.getElementById('tooltip'),
      )}
    </div>
  );
};

export default InfoButton;
