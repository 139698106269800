import { useMemo } from 'react';
import Select from 'react-select';
const DropdownBootstrap = ({
  value: defaultValue = {},
  items: defaultItems = [],
  label,
  onChange,
  error,
  required,
  placeholder,
}) => {
  const items = useMemo(() => {
    let options = defaultItems?.map((data) => {
      return { value: data.id, label: data.title };
    });
    return !options?.length ? [{ label: 'Nothing found', value: 'not_found' }] : options;
  }, [defaultItems]);

  return (
    <div className="form-group mb-3">
      {label && (
        <label className="col-form-label" htmlFor="val-username">
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <Select
        defaultValue={defaultValue}
        onChange={onChange}
        options={items}
        placeholder={placeholder}
        className="form-control p-0 pt-2"
        classNamePrefix="border-start-0 border-end-0 border-bottom-0 border-top-0 shadow-none border-0"
      />
      {error && (
        <div
          id="val-username1-error"
          className="invalid-feedback animated fadeInUp"
          style={{ display: 'block' }}
        >
          {error}
        </div>
      )}
    </div>
  );
};
export default DropdownBootstrap;
